var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "modules-wrap" },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            height: "100%",
            border: true,
            "header-cell-style": { color: "#333333", background: "#EFF6FF" },
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              align: "center",
              width: "100",
              label: "序号",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "buyer_name", align: "center", label: "采购员" },
          }),
          _c("el-table-column", {
            attrs: { prop: "number", align: "center", label: "销售件数" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "statistics_time",
              align: "center",
              label: "时间段",
              "min-width": "150",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "aftersale_total",
              align: "center",
              label: "售后数量",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "aftersale_proportion",
              align: "center",
              label: "售后占比",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "flattenedProducts",
              align: "center",
              label: "售后品类前三",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.getFlattenedProducts(scope.row.flattenedProducts)
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }